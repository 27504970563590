import React from "react";
import ContactTemplate from "@templates/Contact/Contact";

import Layout from "@layouts/Layout";

const seo = {
  title: "Made by Restaurants & Cafe Experts | Restaurant Review",
  description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, iste.",
};

const ContactPage = ({ data: initialData }) => {
  const cmsData = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(cmsData?.seo || {}),
    image: cmsData?.seo?.image?.childImageSharp?.fluid?.src,
  };

  const data = {
    ...(cmsData || {}),
  };

  const navbar = initialData?.navbar?.frontmatter;

  return (
    <Layout navbar={navbar} seo={seo}>
      <ContactTemplate data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPageTemplate {
    navbar: markdownRemark(
      frontmatter: { templateKey: { eq: "NavbarComponent" } }
    ) {
      frontmatter {
        phone
        Items {
          name
          link
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "Contact" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact {
          title
        }
      }
    }
  }
`;

export default ContactPage;
