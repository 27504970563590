import React, { useState } from "react";

import Button from "@components/Button";
import Input from "@components/Input";
import Checkbox from "@components/Checkbox";

import { useToasts } from "react-toast-notifications";

import "./ContactForm.styles.scss";

function encode(data) {
  return Object.keys(data)
    .map(
      key =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
    )
    .join("&");
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getErrors = ({ value, name, required, type }) => {
  if (!value && required) {
    return { name, error: "This field is required" };
  }
  if (value && type === "email") {
    if (!emailRegex.test(String(value).toLowerCase())) {
      return { name, error: "This field must be an email" };
    }
  }
  return null;
};

const dataFields = [
  {
    name: "companyName",
    required: true,
    type: "text",
  },
  {
    name: "name",
    required: true,
    type: "text",
  },
  {
    name: "email",
    required: true,
    type: "email",
  },
  {
    name: "phone",
    required: true,
    type: "text",
  },
];

const initialFields = {
  companyName: "",
  name: "",
  email: "",
  phone: "",
  terms: false,
};

const ContactForm = ({ title }) => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState([]);
  const { addToast } = useToasts();

  const handleChange = e => {
    const {
      target: { name, value, type, checked, required },
    } = e;

    const error = getErrors({
      name,
      value: type === "checkbox" ? checked : value,
      type,
      required,
    });

    if (!error) {
      setErrors(errors.filter(item => item.name !== name));
    } else {
      setErrors([
        ...errors.filter(item => item.name !== name),
        error,
      ]);
    }

    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const errors = dataFields
      .map(item => {
        const error = getErrors({
          ...item,
          value: fields[item.name],
        });

        return error;
      })
      .filter(item => !!item);

    if (!errors.length) {
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encode({
          "form-name": "contact",
          ...fields,
        }),
      })
        .then(() => {
          setFields(initialFields);
          addToast("Message sent successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch(error => console.log(error));
    } else {
      setErrors(errors.filter(item => !!item));
    }
  };
  return (
    <section className="contact-form">
      <div className="contact-form__inner container">
        <h2 className="title-2">{title}</h2>
        <form
          className="contact-form__wrapper"
          name="contact"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="contact-form__row">
            <Input
              placeholder="Restaurant/Cafe Name"
              name="companyName"
              value={fields.companyName}
              onChange={handleChange}
              errors={errors}
              required
            />
            <Input
              placeholder="Your name"
              value={fields.name}
              name="name"
              onChange={handleChange}
              errors={errors}
              required
            />
          </div>
          <div className="contact-form__row">
            <Input
              placeholder="Email"
              name="email"
              type="email"
              value={fields.email}
              onChange={handleChange}
              errors={errors}
              required
            />
            <Input
              placeholder="Phone Number"
              name="phone"
              value={fields.phone}
              onChange={handleChange}
              errors={errors}
              required
            />
          </div>

          <Button className="contact-form__submit" htmlType="submit">
            Submit
          </Button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
