import React from "react";
import ContactForm from "@containers/ContactForm";

const Contact = ({ data }) => {
  return (
    <div className="contact">
      <ContactForm {...data.contact} />
    </div>
  );
};

export default Contact;
