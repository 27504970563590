import React from "react";
import "./Input.styles.scss";

const Input = ({
  id,
  name,
  placeholder,
  label,
  required,
  type,
  onChange,
  errors,
  value,
}) => {
  const errorObj = errors && errors.find(item => item.name === name);
  return (
    <div className="form-control">
      <input
        className="input"
        type={type}
        id={id}
        name={name}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {<div className="error">{errorObj && errorObj.error}</div>}
    </div>
  );
};

export default Input;
